import React from 'react';
import { navigate } from 'gatsby';
import { useViewerStats } from '~/state/hooks/useViewer.ts';

const IndexPage = () => {
  const [, viewerUrl, isViewerLoading] = useViewerStats();

  if (isViewerLoading) {
    return <div></div>;
  }

  navigate(viewerUrl, { replace: true });
  return <div></div>;
};

export default IndexPage;
